$env: riparte;
@import "./theme/riportal-theme.scss";

.GenericForm .GenericFormGroupVar4Title {
  background-color: $base-color;
  /*rgb(29, 93, 148);*/
  color: $white-color;
  height: 2em;
  font-size: 1em;
  margin-top: 0;
  display: flex;
  place-items: center;
  font-weight: bold;
  justify-content: center;
}

.GenericForm .GenericFormGroupVar4 {
  border: lightgray 1px solid !important;
  padding: 10px 0px 10px 0px;
}

.GenericForm .GenericFormGroupVar4 .ms-TextField-fieldGroup,
.GenericForm .GenericFormGroupVar4 .ms-ComboBox,
.GenericForm .GenericFormGroupWithSubGroupVar4 .ms-TextField-fieldGroup,
.GenericForm .GenericFormGroupWithSubGroupVar4 .ms-ComboBox,
.GenericForm .GenericFormSubGroupVar4 .ms-TextField-fieldGroup,
.GenericForm .GenericFormSubGroupVar4 .ms-ComboBox {
  border-radius: 1px;
}

.GenericForm .GenericFormGroupVar4 .buttonActionContainer {
  text-align: center;
  padding-bottom: 10px; //fix?

}

.GenericForm .GenericFormSubGroupVar4Title {
  color: $base-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

.GenericForm .GenericFormFirstSubGroupVar4Title {
  color: $base-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

.GenericForm .GenericFormSubGroupVar4AlertTitle {
  color: $red-color ;
  height: 1em;
  font-size: 1em;
  margin-top: 0;
  font-weight: bold;
  text-align: center;
}

.GenericForm .GenericFormFirstSubGroupVar4 {
  padding: 1em;
}

.GenericForm .GenericFormSubGroupVar4 {
  border-top: lightgray 1px solid !important;
  padding: 1em;
  margin: 15px 0px 15px 0px;
}

.GenericForm .GenericFormGroupTitle {
  margin-top: 5px;
}

.GenericForm .GenericFormGroupVar4 {
  border: none !important;
  padding: 10px 0px 10px 0px;
}

.GenericForm .GenericFormGroupVar4Title {
  @if ($env =='riparte') {
    background: rgba(145, 145, 145, 0.3);
  }

  @else if ($env =='carlink') {
    background: #c1cad2;
  }

  @else if ($env =='drgrandine') {
    background: #daf4f3;
  }

  @else if ($env =='sire') {
    background: #daf4f3;
  }

  @else if ($env =='portal') {
    background: $light-main-color;
  }

  @else if ($env =='portal-clever') {
    background: $light-main-color;
  }

  color: $base-color;
  height: 2em;
  font-size: 1em;
  margin-top: 0;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  font-weight: bold;
  -ms-flex-pack: center;
  justify-content: center;
}

.GenericForm .GenericFormFirstSubGroupVar4Title {
  color: $base-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

/* TITOLO REPAIR PLUS */

.RepairPlus .GenericForm .GenericFormTitleContainer {
  display: flex;
  position: relative;
}

.RepairPlus .GenericForm .GenericFormTitleBar {
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 40px;
  z-index: 99;
}

.RepairPlus .GenericForm .GenericFormTitleBar .title-string {
  background-color: $base-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.RepairPlus .GenericForm .GenericFormRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $base-color;
}

/* GENERIC FORM TITLE RIPARTE */

.GenericForm .GenericFormTitleContainer {
  width: 80% !important;
}

.GenericForm .GenericFormActionContainer {
  display: flex;
  padding: 5px;
  width: 20% !important;
}

.GenericForm .GenericFormRiportalTitleBar .GenericFormTitleContainer {
  display: flex;
  position: relative;
}

.GenericForm .GenericFormRiportalTitleBar {
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.GenericForm .GFHomeFix {
  padding-left: 10px;
}

.GenericForm .GenericFormRiportalTitleBar .title-string {
  background-color: $base-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.GenericForm .GenericFormRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $base-color;
}

.GenericForm .GenericFormRiportalTitleBar .GenericFormActionContainer {
  display: flex;
  justify-content: flex-end !important;
}

.GenericForm .GenericFormActionContainer .top-right-button i {
  color: $base-color;
}

.GenericForm .generate-tax-code {
  padding-top: 20px;
}

.GenericForm .emphasizeDisabledText input:disabled,
.GenericForm .emphasizeDisabledText textarea:disabled,
.GenericForm .emphasizeDisabledText .SelectField .is-disabled input {
  color: rgb(51, 51, 51);
  font-size: 14px;
  font-weight: bold;
}

.GenericForm .itMessage textarea {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: bold;
}

.GenericForm .emphasizeDisabledText label:disabled {
  color: rgb(51, 51, 51);
}

/* DATA TABLE IN GENERIC FORM */

.RepairForm {
  border: none;
}

.RepairForm .DataTable .DataTableBody.tiny-row-body button {
  min-height: auto;
  max-height: none;
  height: auto !important;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
  /* color: $white-color; */
}

.RepairForm .DataTable .scadenziario-header-row {
  background-color: $color8;
  color: $base-color;
}

.RepairForm .ms-FocusZone .ms-DetailsHeader {
  border: none !important;
}

.RepairForm .DataTable .scadenziario-detail-row1 {
  font-weight: lighter;
  background-color: $background-color-row-even;
  min-height: inherit;
  width: 100%;
  border: none;
}

.RepairForm .DataTable .is-selected.scadenziario-nogroup-row1 {
  background-color: $color8;
}

.RepairForm .DataTable .scadenziario-detail-row0:hover,
.RepairForm .DataTable .scadenziario-detail-row1:hover,
.RepairForm .DataTable .is-selected.scadenziario-detail-row0:hover,
.RepairForm .DataTable .is-selected.scadenziario-detail-row1:hover {
  background-color: $color16;
}

.RepairForm .DataTable .scadenziario-nogroup-row0:hover,
.RepairForm .DataTable .scadenziario-nogroup-row1:hover,
.RepairForm .DataTable .is-selected.scadenziario-nogroup-row0:hover,
.RepairForm .DataTable .is-selected.scadenziario-nogroup-row1:hover {
  background-color: $color16;
}

.RepairForm .DataTable .scadenziario-group-row1 {
  background-color: $background-color-row-even;
}

.RepairForm .DataTable .scadenziario-group-row0:hover,
.RepairForm .DataTable .scadenziario-group-row1:hover,
.RepairForm .DataTable .is-selected.scadenziario-group-row0:hover,
.RepairForm .DataTable .is-selected.scadenziario-group-row1:hover {
  background-color: $color16;
}

.RepairForm .GenericInputLabelValue {
  text-align: left;
  width: 62%;
  display: inline-block;
  color: $base-color;
  font-weight: bold;
  padding-top: 1px 0px 1px 0px;
  font-size: 12px;
  user-select: all;
}

.GenericForm .emphasizeDisabledTextGreen input:disabled {
  color: rgb(51, 51, 51);
  font-size: 14px;
  font-weight: bold;
  color: $green-color;
}

.GenericForm .emphasizeDisabledTextRed input:disabled {
  color: rgb(51, 51, 51);
  font-size: 14px;
  font-weight: bold;
  color: $red-color;
}

.GenericForm .emphasizeDisabledText label:disabled,
.GenericForm .emphasizeDisabledTextGreen label:disabled,
.GenericForm .emphasizeDisabledTextRed label:disabled {
  color: rgb(51, 51, 51);
}

/* MULTI SELECTION */

.WagxMultiSelector {
  padding: 20px 15px 20px 15px;
}

.WagxMultiSelectorButtonContainer button.buttonEnabled {
  background-color: $color17 !important;
}

.GenericForm .GenericFormTop {
  margin: 0px !important;
  margin: 0px !important;
}