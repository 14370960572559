$env: riparte;
@import "./theme/riportal-theme.scss";

.DataTable .ms-DetailsList {
  overflow: hidden;
}

.DataTable .DataTableBody.tiny-row-body button {
  min-height: auto;
  max-height: none;
  height: auto !important;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
  /* color: $white-color; */
}

.DataTable .scadenziario-riportal-header-row {
  background-color: $color8;
  color: $base-color;
  font-weight: bold;
}

.ms-FocusZone .ms-DetailsHeader {
  border: none !important;
}

.DataTable .scadenziario-riportal-detail-row1 {
  background-color: $background-color-row-even;
  min-height: inherit;
  width: 100%;
  border: none;
}

.DataTable .scadenziario-riportal-detail-row0:hover .IconSwitchComponent button:disabled,
.DataTable .scadenziario-riportal-detail-row1:hover .IconSwitchComponent button:disabled {
  background-color: $data-table-hover-color;
}

.DataTable .is-selected.scadenziario-riportal-nogroup-row1 {
  background-color: $color8;
}

.DataTable .scadenziario-riportal-detail-row0:hover,
.DataTable .scadenziario-riportal-detail-row1:hover,
.DataTable .is-selected.scadenziario-riportal-detail-row0:hover,
.DataTable .is-selected.scadenziario-riportal-detail-row1:hover {
  background-color: $data-table-hover-color;
}

.DataTable .scadenziario-riportal-nogroup-row0:hover,
.DataTable .scadenziario-riportal-nogroup-row1:hover,
.DataTable .is-selected.scadenziario-riportal-nogroup-row0:hover,
.DataTable .is-selected.scadenziario-riportal-nogroup-row1:hover {
  background-color: $data-table-hover-color;
}

.DataTable .scadenziario-riportal-group-row1 {
  background-color: $background-color-row-even;
}

.DataTable .scadenziario-riportal-group-row0:hover,
.DataTable .scadenziario-riportal-group-row1:hover,
.DataTable .is-selected.scadenziario-riportal-group-row0:hover,
.DataTable .is-selected.scadenziario-riportal-group-row1:hover {
  background-color: $data-table-hover-color;
}

/* BACKGROUND IN ALTO A SX DELLE DATATABLE (ANCHE LA ELENCO ATTIVITà) */

.DataTable .DataTableBodyContainer .ms-DetailsHeader {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px;
  background-color: $color8;
}

.HomeOperativa-crud-taskList .DataTable .DataTableBodyContainer .ms-DetailsHeader {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
  background-color: $white-color;
}

/* ALTRO */

.DataTable .ms-DetailsRow-fields {
  align-items: center !important;
  justify-content: space-around !important;
}

.DataTable .required-0 {
  color: black;
  text-decoration: none;
}

.DataTable .required-1 {
  color: $red-color;
  text-decoration: none;
  font-weight: bold;
}

.DataTable .background-color-0 {
  background-color: rgba(73, 184, 72, 0.5) !important;
}

.DataTable .color-0 {
  color: $green-color !important;
}

.DataTable .background-color-1 {
  background-color: rgba(254, 206, 9, 0.5) !important;
  /* d3ab08 */
}

.DataTable .color-1 {
  color: #fece09 !important;
}

.DataTable .background-color-2 {
  background-color: rgba(211, 32, 39, 0.5) !important;
  /* 96181c */
}

.DataTable .color-2 {
  color: $red-color !important;
}


.DataTable .hover-link a:hover {
  color: $base-color !important;
}

/* TITLE */

.DataTable .DataTableTitleRiportal {
  background: #cbcfd1;
  color: $color13;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.DataTable .DataTableTitleRiportal .DataTableTitleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between
}

.DataTable .DataTableTitleRiportal span {
  padding: 10px;
  position: static;
  background-color: transparent;
}

.DataTable .DataTableTitleRiportal .DataTableRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 39px 0 0 18px;
  border-color: transparent transparent transparent #cbcfd1;
}

.DataTable .DataTableTitleRiportal .DataTableLeftAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 39px 18px;
  border-color: transparent transparent $color4 transparent;
  margin-left: auto;
}

.DataTable .DataTableTitleRiportal {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
  text-transform: uppercase;
  color: $color13;
  background-color: $white-color;
  height: 2.2em;
  min-height: 1px;
  max-height: 2.2em;
  margin: 0px;
  text-align: left;
  display: flex;
  border-bottom: 10px solid $color4;
  border-right: 10px solid $color4;
}

.DataTable .DataTableTitleRiportal .title-string {
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $color13;
  background-color: $color4;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 3px solid $color13;
}

.DataTable .DataTableTitleRiportal .DataTableActionContainer {
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  background-color: $color4;
  border-bottom: 3px solid $color13;
}

.DataTable .DataTableTitleRiportal .top-right-button {
  align-items: center;
}

.DataTable .DataTableTitleRiportal .top-right-button i {
  color: $color13;
}

.DataTable .DataTableCommandBar i {
  color: $base-color;
}

.DataTable .DataTableToolbarOutherGrid .DataTableToolbarButton i {
  color: #414141;
}

.DataTable .DataTableToolbarOutherGrid .DataTableToolbarButton:hover {
  color: $color13;
  font-weight: 600;
}

/* BODY */

.scadenziario-riportal-body {
  border-left: 10px solid #cbcfd1;
  border-right: 10px solid #cbcfd1;
}

.DataTablePagination {
  border-left: 10px solid #cbcfd1;
  border-right: 10px solid #cbcfd1;
  border-bottom: 10px solid #cbcfd1;
}

.DataTableToolbar .filterBar {
  background-color: rgba(203, 207, 209) !important;
}



.DataTableToolbar .filterBox .ms-TextField-fieldGroup,
.DataTableToolbar .filterBox .ms-ComboBox {
  border-radius: 1px !important;
}

/* SPLITTER */

.RepairPlus .splitter-layout .layout-pane {
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.RepairPlus .wagxSplitter .layout-splitter {
  border-bottom: 3px solid $splitter-color;
  border-top: 3px solid $splitter-color;
  background-color: $splitter-color;
}

.RepairPlus .layout-splitter div {
  background-color: $splitter-color !important;
  border: 1px solid $splitter-color !important;
}

/* HEADER TABLE*/

.scadenziario-riportal-header-row .ms-DetailsHeader-cellTitle {
  padding: 0px;
  margin-right: 5px;
}


.fattureDaRegistrareCol .DataTable .ms-DetailsRow-check,
.FattureDaGenerareCol .DataTable .ms-DetailsRow-check,
.configurazioniCarrozzeriaCol .DataTable .ms-DetailsRow-check,
.configurazioniCompagniaCol .DataTable .ms-DetailsRow-check {
  opacity: 1;
}

.fattureDaRegistrareCol .DataTable .ms-DetailsRow-check .ms-Check.is-checked::before,
.FattureDaGenerareCol .DataTable .ms-DetailsRow-check .ms-Check.is-checked::before,
.configurazioniCarrozzeriaCol .DataTable .ms-DetailsRow-check .ms-Check.is-checked::before,
.configurazioniCompagniaCol .DataTable .ms-DetailsRow-check .ms-Check.is-checked::before {
  background-color: $color9;
}


.DataTable .single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DataTable .red {
  color: $red-color !important;
}

.DataTable .green {
  color: $green-color;
}

.DataTable .classDaLeggere {
  font-weight: 700;
  color: $blue-color;
}

.DataTable .NonSpedito {
  color: $red-color;
  font-weight: bold !important;
}

.DataTable .line-through {
  text-decoration: line-through;
}

/* MULTI SELECTION */

.WagxMultiSelector .DataTable .DataTableBodyContainer .DataTableBodyContent .CheckedButtonHidden .ms-Check {
  display: block;
}

/* EMPHASIZE DISABLED TEXT PER INPUT IN UNA DATATABLE */

.DataTable .ms-ComboBox.is-disabled .ms-ComboBox-Input,
.DataTable .is-disabled .ms-TextField-field {
  color: $ultradark-grey-color;
}

// fix css per centrare il checkbox di selezione di una riga multiline

.DataTable .ms-DetailsRow-cellCheck {
  display: flex;
  align-items: center;
}

.DataTable .DataTableToolbar .filterBoxdataRange-inline .ms-GridfilterBoxdataRange-inline .ms-Grid-row div:first-child {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}

.DataTable .DataTableToolbar .filterBoxdataRange-inline .ms-GridfilterBoxdataRange-inline .ms-Grid-row div:last-child {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

// fix css combo tipo generic input dentro dataTable

.DataTableTd button {
  height: 100% !important;
  width: 25% !important;
}


.dataTable-text-align-center {
  text-align: center;
}

.DataTable .DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(odd) {
  padding-left: 0px !important;
}

.DataTable .DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(even) {
  padding-right: 0px !important;
}

.DataTable .scadenziario-body {
  background-color: transparent !important;
}