$env: riparte;
@import "./theme/riportal-theme.scss";

/*.ms-Modal .GenericForm, .ms-Modal .DataTable { */
.ms-Modal .GenericForm {
  padding: 10px;
}

/* GENERIC FORM MODAL */

.ms-Modal .GenericForm .GenericFormTitleContainer {
  display: flex;
  position: relative;
}

.ms-Modal .GenericForm .GenericFormTitleBar {
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 40px;
}

.ms-Modal .GenericForm .GenericFormTitleBar .title-string {
  background-color: $base-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 99;
}

.ms-Modal .GenericForm .GenericFormRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $base-color;
}

.ms-Modal .GenericForm .GenericFormActionContainer {
  display: flex;
  justify-content: flex-end;
}

.ms-Modal .GenericForm .GenericFormGroupVar4Title {
  @if ($env =='riparte') {
    background: rgba(145, 145, 145, 0.3);
  }

  @else if ($env =='carlink') {
    background: #c1cad2;
  }

  @else if ($env =='drgrandine') {
    background: #daf4f3;
  }

  @else if ($env =='sire') {
    background: #daf4f3;
  }

  @else if ($env =='portal') {
    background: $light-main-color;
  }

  @else if ($env =='portal-clever') {
    background: $light-main-color;
  }

  color: $main-color;
  height: 2em;
  font-size: 1em;
  margin-top: 0;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  font-weight: bold;
  -ms-flex-pack: center;
  justify-content: center;
}

.ms-Modal .GenericForm .GenericFormFirstSubGroupVar4Title {
  color: $main-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

.ms-Modal .GenericForm .GenericFormSubGroupVar4 {
  border-top: lightgray 1px solid !important;
  padding: 1em;
  margin: 15px 0px 15px 0px;
}

.ms-Modal .GenericForm .GenericFormGroupVar4 {
  border: none !important;
  padding: 10px 0px 10px 0px;
}

.ms-Modal .PlaceHolder label {
  text-align: left;
}

.ms-Modal .search-body-children {
  margin-top: 0px !important;
}

.ms-Modal .DataTable .DataTableTitle {
  padding: 0;
}

.ms-Modal .closeButton {
  z-index: 100 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* CONFIRM MODAL */

.confirmModal .ms-Dialog-main {
  border-radius: 1px !important;
  width: auto !important;
}

/* MESSAGGE MODAL */

.MessageModal {
  border-radius: 1px !important;
}

/* RICERCA GLOBALE */

.ms-Modal .DataTableTitle {
  background: $main-color;
  color: $white-color;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.ms-Modal .DataTableTitleContainer {
  display: flex;
  width: 80%;
}

.ms-Modal .DataTable .DataTableTitle span {
  padding: 10px;
  position: static;
  background-color: transparent;
}

.ms-Modal .DataTable .DataTableRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 38.5px 0 0 18px;
  border-color: transparent transparent transparent $main-color;
}

.ms-Modal .DataTable .DataTableTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
  text-transform: uppercase;
  color: $white-color;
  background-color: transparent;
  height: 2.2em;
  min-height: 1px;
  max-height: 2.2em;
  margin: 0px;
  text-align: left;
  display: flex;
}

.ms-Modal .DataTable .DataTableTitle {
  padding-top: 5px;
}

.ms-Modal .DataTable .DataTableTitle .title-string {
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  background-color: $main-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.ms-Modal .DataTable .DataTableActionContainer {
  width: 20%;
  display: flex;
  flex-direction: row-reverse;
}

.ms-Modal .DataTable .top-right-button {
  align-items: center;
}

.ms-Modal .DataTable .top-right-button i {
  color: $main-color;
}

.ms-Modal .scadenziario-riportal-body {
  border: none;
}

.ms-Modal .DataTablePagination {
  border: none;
}

.ms-Modal .DataTableToolbar .filterBar {
  background-color: rgba(203, 207, 209) !important;
}

.ms-Modal .DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(odd) {
  padding-left: 0px !important;
}

.ms-Modal .DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(even) {
  padding-right: 0px !important;
}

.longOperationWaitingModal {
  border-radius: 1px !important;
}

/* CLOSE BUTTON */

.search-body .DataTableActionContainer {
  padding-right: 30px;
}

.SearchResultModal {
  border-radius: 1px;
  padding: 0 !important;
}

/* SEARCH MODAL*/

.ms-Modal .SearchResultModal .PivotTop {
  display: flex;
  padding: 10px 10px 0px 10px;
}

.ms-Modal .SearchResultModal .PivotTitleBar {
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 0px;
  margin-bottom: 0px;
  z-index: 99;
}

.ms-Modal .SearchResultModal .PivotTitleContainer {
  display: flex;
  position: relative;
  top: 0px !important;
  left: 0;
}

.ms-Modal .SearchResultModal .PivotTitleBar .title-string {
  background-color: $main-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.ms-Modal .SearchResultModal .PivotRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $main-color;
}


.ms-Modal .SearchResultModal .ms-Pivot {
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 98%;
}

@media screen and (max-width: 1600px) {
  .ms-Modal .SearchResultModal .ms-Pivot {
    display: flex;
    justify-content: left;
    z-index: 100;
    width: 65%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .search-body-children .PivotBody>.ms-FocusZone[role='presentation'] {
    display: flex;
    justify-content: center;
  }
}

.ms-Modal .SearchResultModal .ms-Pivot-link {
  font-weight: 200;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ms-Modal .SearchResultModal .ms-Pivot-link:active {
  background-color: $color12;
}

.ms-Modal .SearchResultModal .ms-Pivot-link.is-selected {
  font-weight: 400;
}

.ms-Modal .SearchResultModal .ms-Pivot-link.is-selected::before {
  border-bottom: 2px solid $main-color !important;
}

.ms-Modal .noteTextArea textarea {
  color: rgb(51, 51, 51);
  font-size: 12px;
}

.ms-Modal .confirm-modal .confirmModalScrollableContent {
  padding: 1em;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .confirm,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .confirm {
  background-color: $green-color;
  color: $white-color;
  margin: 5px;
  flex-grow: 0.1;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .deny,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .undo {
  background-color: #de3c3c;
  color: $white-color;
  margin: 5px;
  flex-grow: 0.1;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .confirm:hover,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .confirm:hover {
  background-color: #2a6b2a;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .deny:hover,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .undo:hover {
  background-color: #96181c;
}

// Modale di errore (componente ErrorBoundary)

.error-boundary .error-boundary-header {
  background: $main-color !important;
}

.error-boundary .error-boundary-footer button.primary {
  background-color: $main-color !important;
  color: $white-color;
}

.error-boundary .error-boundary-footer button.primary:hover {
  background-color: $quaternary-color !important;
  color: $white-color;
}

.error-boundary .error-boundary-footer button {
  background-color: $main-color !important;
  color: $white-color;
}

.error-boundary .error-boundary-footer button:hover {
  background-color: $quaternary-color !important;
  color: $white-color;
}

// Modale di conferma (componente ConfirmModal)

.confirm-modal .confirm-modal-header {
  background: $main-color !important;
}

.confirm-modal .confirm-modal-body .icon-container .question {
  background: $light-main-color !important;
  border-color: $main-color !important;
}

.confirm-modal .confirm-modal-body .icon-container .question i {
  color: $base-color !important;
}

.confirm-modal .confirm-modal-footer button.primary {
  background-color: $main-color !important;
  color: $white-color;
}

.confirm-modal .confirm-modal-footer button.primary:hover {
  background-color: $quaternary-color !important;
  color: $white-color;
}

.confirm-modal .confirm-modal-footer button {
  background-color: $main-color !important;
  color: $white-color;
}

.confirm-modal .confirm-modal-footer button:hover {
  background-color: $quaternary-color !important;
  color: $white-color;
}

// Modale di messaggio (componente MessageModal)

.message-modal .message-modal-header {
  background: $main-color !important;
}

.message-modal .message-modal-body .icon-container .success i {
  color: $base-color !important;
}

.message-modal .message-modal-footer button.primary {
  background-color: $main-color !important;
  color: $white-color;
}

.message-modal .message-modal-footer button.primary:hover {
  background-color: $main-color !important;
  color: $white-color;
}

.message-modal .message-modal-footer button {
  background-color: $main-color !important;
  color: $white-color;
}

.message-modal .message-modal-footer button:hover {
  background-color: $main-color !important;
  color: $white-color;
}

.generic-modal .generic-modal-header {
  background-color: $main-color !important;
  color: white;
}

// SessionWarningModal (modale che la sessione sta scadendo)

.session-warning-modal .session-warning-modal-header {
  background: $main-color !important;
  color: $white-color;
}

.session-warning-modal .session-warning-modal-body .icon-container .warning {
  background: $light-main-color !important;
  border-color: $main-color !important;
}

.session-warning-modal .session-warning-modal-body .icon-container .warning i {
  color: $base-color !important;
}

.session-warning-modal .session-warning-modal-footer button.primary {
  background-color: $main-color !important;
  color: $white-color;
}

.session-warning-modal .session-warning-modal-footer button.primary:hover {
  background-color: $quaternary-color !important;
  color: $white-color;
}