$env: riparte;
@import "./theme/riportal-theme.scss";

/* PIVOT*/

.PivotTop {
  display: flex;
}

.ms-Pivot.ms-Pivot--tabs {
  flex-wrap: wrap;
}

.PivotTitleBar {
  padding-left: 10px;
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 40px;
  z-index: 99;
}

.PivotTitleContainer {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.PivotTitleBar .title-string {
  background-color: $base-color;
  padding: 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  max-width: 20%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.PivotTitleBar .PivotRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $base-color;
}

.ms-Pivot--tabs button {
  margin: 0px 10px 0px 10px;
}

.ms-Pivot--tabs .ms-Pivot-text {
  width: 150px;
}

.ms-Pivot {
  z-index: 100;
  width: 98%;
  display: flex;
}


.PivotBody .ms-Pivot-link {
  font-weight: 200;
  cursor: pointer;
  background-color: $ultralight-grey-color;
  color: $dark-grey-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.PivotBody .ms-Pivot.ms-Pivot--tabs {
  padding-top: 1vh;
}

.ms-Pivot-link:active {
  background-color: $light-grey-color !important;
}

.ms-Pivot-link.is-selected {
  font-weight: 600;
  background-color: $color12 !important;
  color: $dark-grey-color !important;
}

.ms-Pivot-link.is-selected::before {
  border-bottom: 2px solid $color9 !important;
}