$env: riparte;
@import "./theme/riportal-theme.scss";

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.full-height {
  height: 100% !important;
}

html,
#root,
.root-51,
.App {
  height: 100%;
}

.AppContentBody {
  height: calc(100% - 5em);
}

.Popup .AppContentBody {
  height: calc(100% - 4em - 20px) !important;
  overflow-y: auto;
}

// start from popup since its the first parent node to differentiate broadcast-message-home from the one in the home navbar
.Popup .AppHeader .NavBarContainer .broadcast-message-home {
  float: right;
  padding-top: 25px;
  padding-right: 50px;
  z-index: 200;
  position: relative;
}

@keyframes icon-animation-custom {
  0% {
    color: $main-color
  }

  20% {
    color: transparent
  }

  40% {
    color: $main-color
  }

  60% {
    color: transparent
  }

  80% {
    color: $main-color
  }

  100% {
    color: transparent
  }
}

.App .broadcast-message-icon {
  font-size: 38px;
  color: $main-color;
  cursor: pointer;
  animation-name: icon-animation-custom;
  animation-duration: 3s;
  background-size: cover;
  background-position: right;
}

/* CUSTOMIZATONS */

.AppLoader .ImageContainer {
  background-image: url('app/customizations/images/logo_wagx_web.' + $env + '.png') !important;

  @if ($env =='riparte') {
    background-size: cover !important;
  }

  @else if ($env =='carlink') {
    background-size: 80%;
  }

  @else if ($env =='drgrandine') {
    background-size: 80%;
  }

  @else if ($env =='sire') {
    background-size: 80%;
  }

  @else if ($env =='portal') {
    background-size: 100% !important;
  }

  @else if ($env =='portal-clever') {
    background-size: 100% !important;
  }
}

.LoginFormContainer a {
  color: $base-color !important;
}

.LoginForm {
  background-color: $login-background-color !important;
  border-radius: 0.5px !important;
}

.LoginForm .LoginText {
  color: $base-color !important;
}

.LoginForm .ms-MessageBar {
  background-color: $login-background-color !important;
}

.Login {
  background-color: #fff !important;
  background-image: none !important;
}

.Login .LoginButton button {
  background-color: $base-color !important;
  border-radius: 0.5px !important;
  color: #fff !important;
}

.Login .ImageContainer {
  background-image: url('app/customizations/images/logo_wagx_web_login.' + $env + '.png') !important;

  @if ($env =='riparte') {
    background-size: cover !important;
  }

  @else if ($env =='carlink') {
    padding: 6em !important;
    background-size: 65% !important;
  }

  @else if ($env =='drgrandine') {
    padding: 6em !important;
    background-size: 65% !important;
  }

  @else if ($env =='sire') {
    padding: 6em !important;
    background-size: 65% !important;
  }

  @else if ($env =='portal') {
    padding: 6em !important;
  }

  @else if ($env =='portal-clever') {
    padding: 3em !important;
    background-size: 100% !important;
  }
}

.LoginElement label {
  color: $base-color !important;
  font-weight: normal !important;
}

.LoginElement .ms-TextField-fieldGroup {
  background-color: #fff !important;
  border-radius: 1px !important;
}

.WagxAccountPasswordForgotForm .input-element label {
  color: $base-color !important;
}

.WagxAccountPasswordForgotForm .input-element .ms-TextField-fieldGroup {
  background-color: #fff !important;
  border-radius: 1px !important;
  height: 3em;
  width: 30em;
}

.NavBarRightContainer {
  padding-top: 0.4em !important;
}

.NavBarRightContainer .WagxWebNavBarIcon {
  color: $base-color !important;
}

.NavBarRightContainer .WagxWebNavBarIcon:hover {
  color: $base-color !important;
}

.NavBarContainer {
  background-image: url('app/customizations/images/logo_wagx_web.' + $env + '.png') !important;

  @if ($env =='riparte') {
    background-size: 280px !important;
  }

  @else if ($env =='carlink') {
    background-size: 270px !important;
  }

  @else if ($env =='drgrandine') {
    background-size: 180px !important;
  }

  @else if ($env =='sire') {
    background-size: 180px !important;
  }

  @else if ($env =='portal') {
    background-size: 350px !important;
  }

  @else if ($env =='portal-clever') {
    background-size: 450px !important;
  }

  padding: 0px !important;
  height: 100% !important;
}

.NavBarContainer .ms-Persona-primaryText {
  color: $base-color !important;
}

.NavBarContainer .ms-Persona-primaryText:hover {
  color: #cbcfd1 !important;
}

.NavBarContainer .ms-Persona-secondaryText {
  color: $base-color !important;
}

.NavBarContainer .ms-Persona-secondaryText:hover {
  color: #cbcfd1 !important;
}

/* BODY */

.scadenziario-riportal-body {
  border-left: 10px solid #cbcfd1;
  border-right: 10px solid #cbcfd1;
}

.DataTablePagination {
  border-left: 10px solid #cbcfd1;
  border-right: 10px solid #cbcfd1;
  border-bottom: 10px solid #cbcfd1;
}

.HomeOperativa .filtri {
  width: 360px !important;
  border-left: 10px solid #cbcfd1;
  border-bottom: 10px solid #cbcfd1;
  border-top: 10px solid #cbcfd1;
  margin-top: 38px;
  height: calc(100vh - 5.5em - 58px);
}

.HomeOperativa .tabellaDettaglio .DataTable {
  height: 100%;
}

.HomeOperativa .searchButton {
  background-color: #cbcfd1;
}

.HomeOperativa .searchButton div:first-child {
  margin: 0px 0px 10px 0px !important;
}

.HomeOperativa .searchButton .ms-TextField-fieldGroup {
  border-radius: 1px;
  border-color: $base-color;
}

/* CALENDAR */

.HomeOperativa .CalendarFront .CalendarPastEvents,
.HomeOperativa .CalendarBack .CalendarPastEvents {
  border-top: none !important;
  border-bottom: 1px solid $red-color !important;
}

.HomeOperativa .CalendarContainer .Day {
  border-bottom: 1px solid #cbcfd1 !important;
}

.HomeOperativa .CalendarFront .Icon,
.HomeOperativa .CalendarFront .Description,
.HomeOperativa .CalendarFront .Counter,
.HomeOperativa .CalendarBack .Icon,
.HomeOperativa .CalendarBack .Description,
.HomeOperativa .CalendarBack .Counter {
  color: $red-color !important;
}

.HomeOperativa .CalendarFront .CalendarNextEvents {
  border-bottom: 1px solid #cbcfd1 !important;
}

.HomeOperativa .CalendarFront .CalendarNextEvents .Counter {
  /* background-color: #fff!important; */
  background-color: rgba(211, 32, 39, 0.3) !important;
}

.HomeOperativa .Calendar .CalendarDataViewContainer .Calendar-crud-dataList .ms-DetailsRow-fields {
  border-bottom: 1px solid #cbcfd1 !important;
}

/* FIX */

.HomeOperativa-crud-taskList .DataTable .ms-ScrollablePane--contentContainer {
  overflow-x: hidden !important;
}

.HomeOperativa .applyButtonContainer {
  display: none;
}

.HomeOperativa .applyButtonContainer button {
  border: none;
}

.HomeOperativa .HomeOperativa-crud-taskList .ms-DetailsRow .ms-DetailsRow-cell {
  border-bottom: 1px solid #cbcfd1 !important;
}

.HomeOperativa .HomeOperativa-crud-taskList .ms-DetailsRow .DataTableTd {
  overflow: hidden;
  user-select: none;
}

/*SWITCH */

.ms-Toggle-background {
  background-color: #fff;
}

.ms-Check.is-checked::before {
  background-color: $color9;
}

.is-checked .ms-Toggle-background {
  background-color: $base-color;
}

.ms-Toggle-thumb {
  background-color: $base-color;
}

.ms-Toggle-thumb:hover {
  @if ($env =='carlink') {
    background-color: $color9;
  }
}

.is-checked .ms-Toggle-thumb {
  background-color: #fff;
}

/* MESSAGGI DI SUCCESSSO */

.MessageModal .messageModalButtons button {
  background-color: $base-color !important;
}

/* PAGINAZIONE DATATABLE */

.ms-OverflowSet .ms-OverflowSet-item .ms-bgColor-themePrimary {
  background-color: $color9 !important;
}


.DataTablePagination .ms-CommandBar-secondaryCommand i {
  @if ($env =='carlink') {
    color: #25374b !important;
  }
}

/* SPINNER */


.ms-Spinner-label {
  color: $base-color !important;
}

.ms-Spinner-circle {
  border-color: $spinner-color #f2f2f2 #f2f2f2 !important;
}

/* FIX LAYOUT SYSTEM */

.DataTable .DataTableTitle span,
.GenericForm .GenericFormTitleBar span {
  background: $base-color;
}

.ms-ContextualMenu-list i {
  color: $base-color;
}

.ErrorBoundary {
  border-radius: 1px !important;
}

.MenuBar .MenuBarIconsContainer {
  border-bottom: 2px solid white !important;
}

/* SUPER FIX */

.ms-Grid-row {
  margin: 0px !important;
}

.ms-Grid {
  padding: 0px !important;
}

.WagxUserPicker-textfield-container .ms-TextField-field {
  color: $base-color !important;
}

// fix icona Google nella label input indirizzi
.GooglePlacesAutocompleteGoogleLogo {
  height: 14px !important;
}

// fix css Broadcast Message

.broadcast-message-button {
  background-color: $main-color !important;
}

// fix modale CRUD Component riga 540

.ms-Dialog-action .ms-Button--primary {
  background-color: $secondary-color !important;
}

.ms-Dialog-action .ms-Button--primary:hover {
  background-color: $tertiary-color !important;
  color: white;
}

.ms-Dialog-actionsRight {
  display: flex !important;
  justify-content: space-around !important;
}

// fix modale Calcola Cod. Fisc.

.ms-Dialog-actionsRight .ms-Dialog-action .ms-Button--primary {
  background-color: $main-color !important;
}

/* SPLITTER */

.wagxSplitter {
  height: calc(100% - 55px);
}


/* VERTICAL_WIZARD  */

.repair-plus .VerticalWizard {
  height: calc(100% - 90px) !important;
}

.VerticalWizardContent>div {
  width: auto;
  margin: 5px;
  padding: 0.8em;
  box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #aaa;
  border-radius: 0.3em;
  background-color: #FFF;
}

.VerticalWizardBreadCumb {
  background: $main-color !important;
}


.VerticalWizardBreadCumb .BreadCumbButton.Active.First,
.VerticalWizardBreadCumb .BreadCumbButton.Active.Middle,
.VerticalWizardBreadCumb .BreadCumbButton.Middle.Active {
  background: $main-color !important;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header {
  height: 25px;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header .wagx-generic-title {
  background-image: none;
  background-size: initial;
  background-repeat: initial;
  background-position: initial;
  height: 100%;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header .wagx-generic-title .wagx-generic-title-container {
  padding-left: initial;
  padding-right: initial;
  background-color: initial;
  color: initial;
  font-weight: initial;
  margin-left: initial;
  height: 100%;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header .wagx-generic-title .wagx-generic-title-container> :first-child {
  font-weight: bold;
}

.VerticalWizardSaveAndProceedContainer .ms-Button {
  width: auto;
}

.align-button-center {
  text-align: center;
}

// pac-container pac-logo

.pac-container {
  z-index: 1000010;
}

button.ms-Button.ms-Button--default,
button.ms-Button.ms-Button--primary {
  width: 150px;
}