@function main-color() {
    $main-color: white;

    @if ($env =='riparte') {
        $main-color: #2a376f;
    }

    @else if ($env =='carlink') {
        $main-color: #ee6723;
    }

    @else if ($env =='drgrandine') {
        $main-color: #19537a;
    }

    @else if ($env =='sire') {
        $main-color: #2F6AF3;
    }

    @else if ($env =='portal') {
        $main-color: #a5be19;
    }

    @else if ($env =='portal-clever') {
        $main-color: #92b4ce;
    }

    @return $main-color;
}


@function apply-opacity($color, $opacity) {
    @if (type-of($color) == 'color') {
        @return rgba(red($color), green($color), blue($color), $opacity);
    } @else {
        @warn "Invalid color value: #{$color}";
        @return null;
    }
}

@function background-color() {
    $background-color: white;

    @if ($env =='riparte') {
        $background-color: rgb(255, 255, 255, 0.3);
    }

    @else if ($env =='carlink') {
        $background-color: rgb(255, 255, 255, 0.3);
    }

    @else if ($env =='drgrandine') {
        $background-color: rgb(255, 255, 255, 0.3);
    }

    @else if ($env =='sire') {
        $background-color: rgb(255, 255, 255, 0.3);
    }

    @else if ($env =='portal') {
        $background-color: rgb(255, 255, 255, 0.3);
    }

    @else if ($env =='portal-clever') {
        $background-color: rgb(255, 255, 255, 0.3);
    }

    @return $background-color;
}

@function secondary-color() {
    $secondary-color: white;

    @if ($env =='riparte') {
        $secondary-color: #fff;
    }

    @else if ($env =='carlink') {
        $secondary-color: #fff;
    }

    @else if ($env =='drgrandine') {
        $secondary-color: #fff;
    }

    @else if ($env =='sire') {
        $secondary-color: #fff;
    }

    @else if ($env =='portal') {
        $secondary-color: #676767;
    }

    @else if ($env =='portal-clever') {
        $secondary-color: #1d5c93;
    }

    @return $secondary-color;
}

@function light-main-color() {
    $light-main-color: white;

    @if ($env =='riparte') {
        $light-main-color: #d8def5;
    }

    @else if ($env =='carlink') {
        $light-main-color: #ececec;
    }

    @else if ($env =='drgrandine') {
        $light-main-color: #daf4f3;
    }

    @else if ($env =='sire') {
        $light-main-color: rgb(255, 162, 156);
    }

    @else if ($env =='portal') {
        $light-main-color: #edf4d3;
    }

    @else if ($env =='portal-clever') {
        $light-main-color: #bbd0e6;
    }

    @return $light-main-color;
}

@function tertiary-color() {
    $tertiary-color: white;

    @if ($env =='riparte') {
        $tertiary-color: #cbcfd1;
    }

    @else if ($env =='carlink') {
        $tertiary-color: #25374b;
    }

    @else if ($env =='drgrandine') {
        $tertiary-color: #4ec5c7;
    }

    @else if ($env =='sire') {
        $tertiary-color: #E2281B;
    }

    @else if ($env =='portal') {
        $tertiary-color: #a5be19;
    }

    @else if ($env =='portal-clever') {
        $tertiary-color: #d5d329;
    }

    @return $tertiary-color;
}

@function quaternary-color() {
    $quaternary-color: white;

    @if ($env =='riparte') {
        $quaternary-color: #d8def5;
    }

    @else if ($env =='carlink') {
        $quaternary-color: #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $quaternary-color: #ebac08;
    }

    @else if ($env =='sire') {
        $quaternary-color: rgb(255, 162, 156);
    }

    @else if ($env =='portal') {
        $quaternary-color: white;
    }

    @else if ($env =='portal-clever') {
        $quaternary-color: white;
    }

    @return $quaternary-color;
}


@function text-header-color() {
    $text-header-color: white;

    @if ($env =='riparte') {
        $text-header-color: #676767;
    }

    @else if ($env =='carlink') {
        $text-header-color: #1d5c93;
    }

    @else if ($env =='drgrandine') {
        $text-header-color: #676767;
    }

    @else if ($env =='sire') {
        $text-header-color: #676767;
    }

    @else if ($env =='portal') {
        $text-header-color: #676767;
    }

    @else if ($env =='portal-clever') {
        $text-header-color: #1d5c93;
    }

    @return $text-header-color;
}


@function button-primary-color() {
    $button-primary-color: white;

    @if ($env =='riparte') {
        $button-primary-color: #919394;
    }

    @else if ($env =='carlink') {
        $button-primary-color: #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $button-primary-color: #919394;
    }

    @else if ($env =='sire') {
        $button-primary-color: #919394;
    }

    @else if ($env =='portal') {
        $button-primary-color: #919394;
    }

    @else if ($env =='portal-clever') {
        $button-primary-color: #919394;
    }

    @return $button-primary-color;
}

@function base-color() {
    $base-color: white;

    @if ($env =='riparte') {
        $base-color: #2a376f;
    }

    @else if ($env =='carlink') {
        $base-color: #25374b;
    }

    @else if ($env =='drgrandine') {
        $base-color: #19537a;
    }

    @else if ($env =='sire') {
        $base-color: #003f7b;
    }

    @else if ($env =='portal') {
        $base-color: #a5be19;
    }

    @else if ($env =='portal-clever') {
        $base-color: #92b4ce;
    }

    @return $base-color;
}

@function ultralight-grey-color() {
    $ultralight-grey-color: white;

    @if ($env =='riparte') {
        $ultralight-grey-color: #f4f4f4;
    }

    @else if ($env =='carlink') {
        $ultralight-grey-color: #dfe2e6;
    }

    @else if ($env =='drgrandine') {
        $ultralight-grey-color: #f4f4f4;
    }

    @else if ($env =='sire') {
        $ultralight-grey-color: #f4f4f4;
    }

    @else if ($env =='portal') {
        $ultralight-grey-color: #f0f0f0;
    }

    @else if ($env =='portal-clever') {
        $ultralight-grey-color: #f0f0f0;
    }

    @return $ultralight-grey-color;
}

@function button-action-background-color() {
    $button-action-background-color: white;

    @if ($env =='riparte') {
        $button-action-background-color: rgb(16, 110, 190);
    }

    @else if ($env =='carlink') {
        $button-action-background-color: #ef6322;
    }

    @else if ($env =='drgrandine') {
        $button-action-background-color: rgb(16, 110, 190);
    }

    @else if ($env =='sire') {
        $button-action-background-color: rgb(16, 110, 190);
    }

    @else if ($env =='portal') {
        $button-action-background-color: rgb(16, 110, 190);
    }

    @else if ($env =='portal-clever') {
        $button-action-background-color: rgb(16, 110, 190);
    }

    @return $button-action-background-color;
}

@function button-action-background-color-hover() {
    $button-action-background-color-hover: white;

    @if ($env =='riparte') {
        $button-action-background-color-hover: rgba(16, 110, 190, 0.8);
    }

    @else if ($env =='carlink') {
        $button-action-background-color-hover: #cf4e13;
    }

    @else if ($env =='drgrandine') {
        $button-action-background-color-hover: rgba(16, 110, 190, 0.8);
    }

    @else if ($env =='sire') {
        $button-action-background-color-hover: rgba(16, 110, 190, 0.8);
    }

    @else if ($env =='portal') {
        $button-action-background-color-hover: rgba(16, 110, 190, 0.8);
    }

    @else if ($env =='portal-clever') {
        $button-action-background-color-hover: rgba(16, 110, 190, 0.8);
    }

    @return $button-action-background-color-hover;
}

@function login-background-color() {
    $login-background-color: white;

    @if ($env =='riparte') {
        $login-background-color: #cbcfd1;
    }

    @else if ($env =='carlink') {
        $login-background-color: #cbcfd1;
    }

    @else if ($env =='drgrandine') {
        $login-background-color: #cbcfd1;
    }

    @else if ($env =='sire') {
        $login-background-color: #cbcfd1;
    }

    @else if ($env =='portal') {
        $login-background-color: #cbcfd1;
    }

    @else if ($env =='portal-clever') {
        $login-background-color: #cbcfd1;
    }

    @return $login-background-color;
}

@function background-color-right-box() {
    $background-color-right-box: white;

    @if ($env =='riparte') {
        $background-color-right-box : #5c6c9e;
    }

    @else if ($env =='carlink') {
        $background-color-right-box : #fc6d1f;
    }

    @else if ($env =='drgrandine') {
        $background-color-right-box : #48abce;
    }

    @else if ($env =='sire') {
        $background-color-right-box : #2F6AF3;
    }

    @else if ($env =='portal') {
        $background-color-right-box: #a5be19;
    }

    @else if ($env =='portal-clever') {
        $background-color-right-box: #92b4ce;
    }

    @return $background-color-right-box;
}

@function header-color-generic-form() {
    $header-color-generic-form: white;

    @if ($env =='riparte') {
        $header-color-generic-form : rgba(145, 145, 145, 0.3);
    }

    @else if ($env =='carlink') {
        $header-color-generic-form : #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $header-color-generic-form : #daf4f3;
    }

    @else if ($env =='sire') {
        $header-color-generic-form : #E2281B;
    }

    @else if ($env =='portal') {
        $header-color-generic-form : white;
    }

    @else if ($env =='portal-clever') {
        $header-color-generic-form : white;
    }

    @return $header-color-generic-form;
}

@function background-color-desk-left-right-section() {
    $background-color-desk-left-right-section: white;

    @if ($env =='riparte') {
        $background-color-desk-left-right-section : #cbcfd1;
    }

    @else if ($env =='carlink') {
        $background-color-desk-left-right-section : #cbcfd1;
    }

    @else if ($env =='drgrandine') {
        $background-color-desk-left-right-section : #cbcfd1;
    }

    @else if ($env =='sire') {
        $background-color-desk-left-right-section : #cbcfd1;
    }

    @else if ($env =='portal') {
        $background-color-desk-left-right-section : #cbcfd1;
    }

    @else if ($env =='portal-clever') {
        $background-color-desk-left-right-section : #cbcfd1;
    }

    @return $background-color-desk-left-right-section;
}

@function color4() {
    $color4 : white;

    @if ($env =='riparte') {
        $color4 : #cbcfd1;
    }

    @else if ($env =='carlink') {
        $color4 : #25374b;
    }

    @else if ($env =='drgrandine') {
        $color4 : #cbcfd1;
    }

    @else if ($env =='sire') {
        $color4 : #cbcfd1;
    }

    @else if ($env =='portal') {
        $color4 : #cbcfd1;
    }

    @else if ($env =='portla-clever') {
        $color4 : #cbcfd1;
    }


    @return $color4;
}

@function button-primary-disabled-backgroundcolor() {
    $button-primary-disabled-backgroundcolor : white;

    @if ($env =='riparte') {
        $button-primary-disabled-backgroundcolor : #919394;
    }

    @else if ($env =='carlink') {
        $button-primary-disabled-backgroundcolor : #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $button-primary-disabled-backgroundcolor : #919394;
    }

    @else if ($env =='sire') {
        $button-primary-disabled-backgroundcolor : #919394;
    }

    @else if ($env =='portal') {
        $button-primary-disabled-backgroundcolor : #919394;
    }

    @else if ($env =='portal-clever') {
        $button-primary-disabled-backgroundcolor : #919394;
    }


    @return $button-primary-disabled-backgroundcolor;
}

@function background-color-row-odd() {
    $background-color-row-odd : white;

    @if ($env =='riparte') {
        $background-color-row-odd : #cecece;
    }

    @else if ($env =='carlink') {
        $background-color-row-odd : #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $background-color-row-odd : #cecece;
    }

    @else if ($env =='sire') {
        $background-color-row-odd : #cecece;
    }

    @else if ($env =='portal') {
        $background-color-row-odd : #cecece;
    }

    @else if ($env =='portal-clever') {
        $background-color-row-odd : #cecece;
    }

    @return $background-color-row-odd;
}

@function color8() {
    $color8 : white;

    @if ($env =='riparte') {
        $color8 : #d8def5;
    }

    @else if ($env =='carlink') {
        $color8 : #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $color8 : #daf4f3;
    }

    @else if ($env =='sire') {
        $color8 : rgb(255, 162, 156);
    }

    @else if ($env =='portal') {
        $color8 : white;
    }

    @else if ($env =='portal-clever') {
        $color8 : white;
    }

    @return $color8;
}

@function color9() {
    $color9 : white;

    @if ($env =='riparte') {
        $color9 : #2a376f;
    }

    @else if ($env =='carlink') {
        $color9 : #ee6723;
    }

    @else if ($env =='drgrandine') {
        $color9 : #19537a;
    }

    @else if ($env =='sire') {
        $color9 : #2F6AF3;
    }

    @else if ($env =='portal') {
        $color9: #a5be19;
    }

    @else if ($env =='portal-clever') {
        $color9: #92b4ce;
    }

    @return $color9;
}

@function data-table-body-background-color() {
    $data-table-body-background-color: white;

    @if ($env =='riparte') {
        $data-table-body-background-color : #d9d9d9;
    }

    @else if ($env =='carlink') {
        $data-table-body-background-color : #dfe2e6;
    }

    @else if ($env =='drgrandine') {
        $data-table-body-background-color : #d9d9d9;
    }

    @else if ($env =='sire') {
        $data-table-body-background-color : #d9d9d9;
    }

    @else if ($env =='portal') {
        $data-table-body-background-color : #d9d9d9;
    }

    @else if ($env =='portal-clever') {
        $data-table-body-background-color : #d9d9d9;
    }

    @return $data-table-body-background-color;
}

@function background-color-row-even() {
    $background-color-row-even: white;

    @if ($env =='riparte') {
        $background-color-row-even : #f2f2f2;
    }

    @else if ($env =='carlink') {
        $background-color-row-even : #dfe2e6;
    }

    @else if ($env =='drgrandine') {
        $background-color-row-even : #f2f2f2;
    }

    @else if ($env =='sire') {
        $background-color-row-even : #f2f2f2;
    }

    @else if ($env =='portal') {
        $background-color-row-even : #f2f2f2;
    }

    @else if ($env =='portal-clever') {
        $background-color-row-even : #f2f2f2;
    }

    @return $background-color-row-even;
}

@function data-table-hover-color() {
    $data-table-hover-color: white;

    @if ($env =='riparte') {
        $data-table-hover-color : #d8def5;
    }

    @else if ($env =='carlink') {
        $data-table-hover-color : #e8c4ad;
    }

    @else if ($env =='drgrandine') {
        $data-table-hover-color : #daf4f3;
    }

    @else if ($env =='sire') {
        $data-table-hover-color : rgb(255, 162, 156);
    }

    @else if ($env =='portal') {
        $data-table-hover-color : white;
    }

    @else if ($env =='portal-clever') {
        $data-table-hover-color : white;
    }

    @return $data-table-hover-color;
}

@function color12() {
    $color12: white;

    @if ($env =='riparte') {
        $color12 : #d8def5;
    }

    @else if ($env =='carlink') {
        $color12 : #e8c4ad;
    }

    @else if ($env =='drgrandine') {
        $color12 : #daf4f3;
    }

    @else if ($env =='sire') {
        $color12 : rgb(255, 162, 156);
    }

    @else if ($env =='portal') {
        $color12 : white;
    }

    @else if ($env =='portal-clever') {
        $color12 : white;
    }

    @return $color12;
}


@function color13() {
    $color13: white;

    @if ($env =='riparte') {
        $color13 : #2a376f;
    }

    @else if ($env =='carlink') {
        $color13 : #fff;
    }

    @else if ($env =='drgrandine') {
        $color13 : #19537a;
    }

    @else if ($env =='sire') {
        $color13 : #2F6AF3;
    }

    @else if ($env =='portal') {
        $color13 : white;
    }

    @else if ($env =='portal-clever') {
        $color13 : white;
    }

    @return $color13;
}

@function color16() {
    $color16: white;

    @if ($env =='riparte') {
        $color16 : #d8def5;
    }

    @else if ($env =='carlink') {
        $color16 : #eae9e2;
    }

    @else if ($env =='drgrandine') {
        $color16 : #daf4f3;
    }

    @else if ($env =='sire') {
        $color16 : #E2281B;
    }

    @else if ($env =='portal') {
        $color16 : white;
    }

    @else if ($env =='portal-clever') {
        $color16 : white;
    }

    @return $color16;
}

@function color17() {
    $color17: white;

    @if ($env =='riparte') {
        $color17 : #2a376f;
    }

    @else if ($env =='carlink') {
        $color17 : #ef6322;
    }

    @else if ($env =='drgrandine') {
        $color17 : #19537a;
    }

    @else if ($env =='sire') {
        $color17 : #2F6AF3;
    }

    @else if ($env =='portal') {
        $color17 : white;
    }

    @else if ($env =='portal-clever') {
        $color17 : white;
    }

    @return $color17;
}

@function repair-access-image-color() {
    $repair-access-image-color: white;

    @if ($env =='riparte') {
        $repair-access-image-color : #656565;
    }

    @else if ($env =='carlink') {
        $repair-access-image-color : #e8c4ad;
    }

    @else if ($env =='drgrandine') {
        $repair-access-image-color : #656565;
    }

    @else if ($env =='sire') {
        $repair-access-image-color : #656565;
    }

    @else if ($env =='portal') {
        $repair-access-image-color : #656565;
    }

    @else if ($env =='portal-clever') {
        $repair-access-image-color : #656565;
    }

    @return $repair-access-image-color;
}

@function splitter-color() {
    $splitter-color: white;

    @if ($env =='riparte') {
        $splitter-color : #616e9b;
    }

    @else if ($env =='carlink') {
        $splitter-color : #c1cad2;
    }

    @else if ($env =='drgrandine') {
        $splitter-color : #48abce;
    }

    @else if ($env =='sire') {
        $splitter-color : #2F6AF3;
    }

    @else if ($env =='sire') {
        $splitter-color : #2F6AF3;
    }

    @else if ($env =='portal') {
        $splitter-color: #a5be19;
    }

    @return $splitter-color;
}


@function color23() {
    $color23: white;

    @if ($env =='riparte') {
        $color23 : #fff;
    }

    @else if ($env =='carlink') {
        $color23 : #000;
    }

    @else if ($env =='drgrandine') {
        $color23 : #fff;
    }

    @else if ($env =='sire') {
        $color23 : #fff;
    }

    @else if ($env =='portal') {
        $color23 : #fff;
    }

    @else if ($env =='portal-clever') {
        $color23 : #fff;
    }

    @return $color23;
}

@function user-picker-backgroundcolor() {
    $user-picker-backgroundcolor: white;

    @if ($env =='riparte') {
        $user-picker-backgroundcolor : #cbcfd1;
    }

    @else if ($env =='carlink') {
        $user-picker-backgroundcolor : #cbcfd1;
    }

    @else if ($env =='drgrandine') {
        $user-picker-backgroundcolor : #cbcfd1;
    }

    @else if ($env =='sire') {
        $user-picker-backgroundcolor : #cbcfd1;
    }

    @else if ($env =='portal') {
        $user-picker-backgroundcolor : #cbcfd1;
    }

    @else if ($env =='portal-clever') {
        $user-picker-backgroundcolor : #cbcfd1;
    }

    @return $user-picker-backgroundcolor;
}

@function spinner-color() {
    $spinner-color: white;

    @if ($env =='riparte') {
        $spinner-color : #2a376f;
    }

    @else if ($env =='carlink') {
        $spinner-color : #ee6723;
    }

    @else if ($env =='drgrandine') {
        $spinner-color : #19537a;
    }

    @else if ($env =='sire') {
        $spinner-color : #E2281B;
    }

    @else if ($env =='sire') {
        $spinner-color : #2F6AF3;
    }

    @else if ($env =='portal') {
        $spinner-color: #a5be19;
    }

    @return $spinner-color;
}

@function background-color-desk-tab-item() {
    $background-color-desk-tab-item: white;

    @if ($env =='riparte') {
        $background-color-desk-tab-item: #616e9b;
    }

    @else if ($env =='carlink') {
        $background-color-desk-tab-item: #ef6322;
    }

    @else if ($env =='drgrandine') {
        $background-color-desk-tab-item: #48abce;
    }

    @else if ($env =='sire') {
        $background-color-desk-tab-item: #2F6AF3;
    }

    @else if ($env =='portal') {
        $background-color-desk-tab-item: white;
    }

    @else if ($env =='portal-clever') {
        $background-color-desk-tab-item: white;
    }

    @return $background-color-desk-tab-item;
}

@function background-color-right-section() {
    $background-color-right-section: white;

    @if ($env =='riparte') {
        $background-color-right-section: rgba(97, 110, 155, 0.65);
    }

    @else if ($env =='carlink') {
        $background-color-right-section: rgba(239, 99, 34, 0.65);
    }

    @else if ($env =='drgrandine') {
        $background-color-right-section: rgba(72, 171, 206, 0.65);
    }

    @else if ($env =='sire') {
        $background-color-right-section: rgba(47, 106, 243, 0.65);
    }

    @else if ($env =='portal') {
        $background-color-right-section: white;
    }

    @else if ($env =='portal-clever') {
        $background-color-right-section: white;
    }

    @return $background-color-right-section;
}

$color4 :color4();
$color8 :color8();
$color9 :color9();
$color12: color12();
$color13: color13();
$color16: color16();
$color17: color17();
$color23: color23();


$main-color: main-color();
$secondary-color: secondary-color();
$light-main-color: light-main-color();
$tertiary-color: tertiary-color();
$quaternary-color: quaternary-color();
$background-color: background-color();
$ultradark-grey-color: rgb(51, 51, 51);
$dark-grey-color: #676767;
$light-grey-color: #d0d0d0;
$ultralight-grey-color: ultralight-grey-color();
$login-background-color: login-background-color();
$yellow-color: yellow-color();
$light-yellow-color: light-yellow-color();
$background-color-right-box: background-color-right-box();
$header-color-generic-form: header-color-generic-form();
$background-color-desk-left-right-section: background-color-desk-left-right-section();
$splitter-color: splitter-color();
$user-picker-backgroundcolor: user-picker-backgroundcolor();
$text-header-color: text-header-color();
$button-primary-disabled-backgroundcolor :button-primary-disabled-backgroundcolor();
$data-table-hover-color: data-table-hover-color();
$data-table-body-background-color: data-table-body-background-color();
$background-color-row-even: background-color-row-even();
$background-color-row-odd :background-color-row-odd();
$repair-access-image-color: repair-access-image-color();

$white-color: #ffffff;
$black-color: #000000;

$dark-green-color: rgba(73, 184, 72, 0.5);
$green-color: #49b848;
$dark-yellow-color: rgba(254, 206, 9, 0.5);
$yellow-color: #fece09;
$dark-red-color: rgba(211, 32, 39, 0.5);
$red-color: #d32027;
$blue-color: #0000FF;
$button-primary-color: button-primary-color();
$base-color: base-color();
$button-action-background-color: button-action-background-color();
$button-action-background-color-hover: button-action-background-color-hover();
$background-color-prev-freddo-TECNICO: rgb(255, 208, 197);
$background-color-prev-freddo-COMPAGNIA: rgb(179, 231, 175);
$background-color-desk-tab-item: background-color-desk-tab-item();
$background-color-right-section: background-color-right-section();
$spinner-color: spinner-color();